<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>添加组合</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table" style="width: 50%; margin-top: 10px">
        <el-form :model="addGoodsForm" ref="addGoodsFormRef" label-width="100px">
          <el-form-item label="关联名称" prop="title">
            <el-input v-model="addGoodsForm.title"></el-input>
          </el-form-item>
          
          <el-form-item label="包含商品" prop="goods">
            <el-row class="select-row" v-for="(item,index) in addGoodsForm.goods" :key="index">
              <span>{{item.title}}</span>
              <i class="close-btn el-icon-close" @click="deleteOne(index)"></i>
            </el-row>
            
            <el-row>
              <div class="add-btn" @click="addOne">添加商品</div>
            </el-row>
          </el-form-item>

          <!-- 操作 -->
          <el-form-item>
            <el-button type="primary" class="btnAdd" @click="addGoods()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-dialog title="关联商品" :visible.sync="dialogVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px" style="height: 250px;overflow: auto;">
        <el-row v-for="(item,index) in tempList" :key="index" >
           <el-checkbox v-model="item.checked">{{item.title}}</el-checkbox>
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goodsList: [],
      addGoodsForm: {
        title: '',
        goods: []
      },
      isEdit: false,
      groupInfo: null,
      dialogVisible: false,
      tempList: []
    } 
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.groupInfo = localStorage.getItem(query.id);
      if(this.groupInfo){
        this.groupInfo = JSON.parse(this.groupInfo);
        this.isEdit = true;
        this.addGoodsForm.title = this.groupInfo.name;
        this.addGoodsForm.goods = this.groupInfo.goodsEntityList
      }
    }
    // 商品列表
    this.getGoods()
  },
  methods: {
    //  获取商品列表信息
    getGoods () {
      
      this.$api.QueryGoodsToEvaluateGroup({currentPage: 1, pageSize: 200}).then((res) => {
        if (res.code !== 200) this.$message.error('请求商品列表失败');
        let existGoods = [];
        if(this.isEdit){
          existGoods = this.addGoodsForm.goods || [];
        }
        this.goodsList = [...existGoods, ...res.data ];
        if(!this.isEdit){
          this.addGoodsForm.goods = [this.goodsList[0]];
        }
      })
    },
    closeDialog(){
      this.addGoodsForm.goods = this.tempList.filter(o => o.checked)
      this.tempList = [];
      this.dialogVisible = false;
    },
    addOne(){
      this.tempList = this.goodsList.map(o => {
        const obj = {...o};
        obj.checked = false;
        if(this.addGoodsForm.goods.find(e => e.goodsId == obj.goodsId)){
          obj.checked = true;
        }
        return obj;
      })
      this.dialogVisible = true;
    },
    deleteOne(i){
      if(this.addGoodsForm.goods.length < 2) return this.$message.error('至少添加一个商品')
      let list = this.addGoodsForm.goods.filter((o, idx) => idx != i);
      this.addGoodsForm.goods = [...list];
    },
    // 添加商品
    addGoods () {
      if(!this.addGoodsForm.title) return this.$message.error('请输入组合名称');
      
      if(this.isEdit){
        var params = {
          groupId: this.groupInfo.groupId,
          id: this.groupInfo.id,
          name: this.addGoodsForm.title,
          goodsList: this.addGoodsForm.goods.map(o => o.goodsId)
        }
        this.$api.UpdateGoodsEvaluateGroup(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('编辑组合失败')
          } 
          this.$message.success('编辑组合成功')
          this.$router.push('/commentGoods')
        })
      }else{
        var params = {
          name: this.addGoodsForm.title,
          goodsList: this.addGoodsForm.goods.map(o => o.goodsId)
        }
        this.$api.AddGoodsEvaluateGroup(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('添加组合失败')
          } 
          this.$message.success('添加组合成功')
          this.$router.push('/commentGoods')
        })
      }
      
    },

  },
}
</script>

<style lang="scss" scoped>
  .el-select{
    width:100%;
    margin-bottom: 10px;
  }
  .add-btn{
    width: 100%;
    border: 1px dashed #ccc;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .select-row{
    position: relative;

  }
  .close-btn{
    position: absolute;
    right: -20px;
    top: 14px;
    cursor: pointer
  }
</style>
